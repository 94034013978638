.customCursor {
	cursor: pointer;
}

body {
	overflow-x: hidden;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
	margin-right: 0 !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
	right: unset !important	;
	left: 0.75rem !important;
}

.p-dropdown-panel .p-dropdown-items {
	display: flex;
	flex-direction: column;
	z-index: 99999999;
}

.order-badge {
	border-radius: var(--border-radius);
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;
}
.order-badge {
	&.status-pending {
		background: #f3a73a;
		color: #ffffff;
	}
	&.status-delivered {
		background: #c8e6c9;
		color: #256029;
	}
	&.status-shipped {
		background: #1e91cf;
		color: #ffffff;
	}
	&.status-ready {
		background: #2a2ce0;
		color: #ffffff;
	}
	&.status-processing {
		background: #54b7d3;
		color: #ffffff;
	}
	&.status-canceled {
		background: #ab1600;
		color: #ffffff;
	}
	&.status-refunded {
		background: #ffd900;
		color: #ffffff;
	}
	&.status-unpaid {
		background: #ff0000;
		color: #ffffff;
	}
	&.status-paid {
		background: #c8e6c9;
		color: #256029;
	}
	&.status-other {
		background: #7c7c7c;
		color: #ffffff;
	}
}

.status-badge {
	border-radius: var(--border-radius);
	padding: 0.25em 0.5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.3px;
}
.status-badge {
	&.status-new {
		background: #ff2200;
		color: #ffffff;
	}
	&.status-read {
		background: #00cc07;
		color: #ffffff;
	}
}

.section-b-space {
	padding-bottom: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.invoice-wrapper {
	background-color: white;
	-webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
	box-shadow: #e6e6e6 0px 0px 14px 3px;
	position: relative;
}
.invoice-wrapper h2 {
	font-size: 30px;
}
.invoice-wrapper h4 {
	color: #646464;
}
.invoice-wrapper .invoice-detail h5 {
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 600;
}
.invoice-wrapper .invoice-detail h6 {
	margin-bottom: 0;
	font-size: 16px;
	color: #6d6d6d;
}
.invoice-wrapper .invoice-body {
	padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.invoice-wrapper .invoice-body .table th,
.invoice-wrapper .invoice-body .table td {
	border-top: none;
}
.invoice-wrapper .invoice-body .table {
	font-size: 16px;
}
.invoice-wrapper .invoice-body .table thead th {
	text-transform: uppercase;
	font-weight: 400;
	padding-bottom: 16px;
	color: black;
	border-color: var(--theme-deafult) !important;
}
.invoice-wrapper .invoice-body .table td {
	color: #646464;
}
.invoice-wrapper .invoice-body .table tbody td,
.invoice-wrapper .invoice-body .table tbody th {
	padding-top: 10px;
	padding-bottom: 10px;
	text-transform: capitalize;
}
.invoice-wrapper .invoice-body .table tbody th {
	font-weight: normal;
	color: #646464;
}
.invoice-wrapper .invoice-body .table tbody tr:first-child td,
.invoice-wrapper .invoice-body .table tbody tr:first-child th {
	padding-top: 22px;
}
.invoice-wrapper .invoice-body .table tbody tr:last-child td,
.invoice-wrapper .invoice-body .table tbody tr:last-child th {
	padding-bottom: 22px;
}
.invoice-wrapper .invoice-body .table tfoot {
	border-top: 1px solid #ddd;
}
.invoice-wrapper .invoice-body .table tfoot td {
	padding-top: 15px;
}
.invoice-wrapper .invoice-footer {
	padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
}
.invoice-wrapper .font-bold {
	font-weight: bold;
}
.invoice-wrapper .authorise-sign {
	position: absolute;
	bottom: calc(13px + (40 - 13) * ((100vw - 320px) / (1920 - 320)));
	text-align: center;
}
.invoice-wrapper .authorise-sign h6 {
	margin-bottom: 0;
	font-size: 18px;
	color: black;
	font-weight: bold;
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid #ddd;
	line-height: 1;
}

.theme-invoice-1 .invoice-header {
	padding: calc(15px + (45 - 15) * ((100vw - 320px) / (1920 - 320)));
	background-color: #f8f8f8;
}
.theme-invoice-1 .invoice-header h4 {
	font-size: 16px;
	color: #646464;
	margin-bottom: 5px;
}
.theme-invoice-1 .invoice-header .upper-icon {
	position: absolute;
	width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
	height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
	top: 0;
	right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: var(--theme-deafult);
}
.theme-invoice-1 .invoice-header .upper-icon img {
	width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}
.theme-invoice-1 .invoice-header .header-content {
	margin-top: 50px;
}
.theme-invoice-1 .invoice-header .header-content h2 {
	font-size: 30px;
}
.theme-invoice-1 .invoice-header .detail-bottom {
	padding-top: 24px;
	margin-top: 24px;
	border-top: 1px solid #dddddd;
}
.theme-invoice-1 .invoice-header .detail-bottom ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.theme-invoice-1 .invoice-header .detail-bottom ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 16px;
}
.theme-invoice-1 .invoice-header .detail-bottom ul li span {
	color: var(--theme-deafult);
	text-transform: capitalize;
}
.theme-invoice-1 .invoice-header .detail-bottom ul li h4 {
	margin-bottom: 0;
	margin-left: 4px;
}

@media (max-width: 767px) {
	.theme-invoice-1 .invoice-header .detail-bottom ul {
		display: block;
	}
}

.p-paginator .p-dropdown .p-dropdown-label {
	padding-right: 0.75rem !important;
}
